import aboutLeft from '../assets/about-stock-transparent.png';
import logo from '../assets/logo-light.png';
import logo2 from '../assets/logo-dark.png';
import book from '../assets/osir-book.png';
import history from '../assets/history.png';
import logo3 from '../assets/logo-3.png';
import hptext from '../assets/hp-text.png'

export default {
    aboutLeft,
    logo,
    logo2,
    logo3,
    book,
    history,
    hptext
}