import React, {useState, useEffect} from 'react';
import {
    FaFacebookF, FaHandPointRight,
    FaInstagram, FaRegEye, FaRegHandshake,
    FaTiktok,
    FaTwitter,
    FaYoutube
} from "react-icons/fa";
import {client, urlFor} from "../client";
import {Link, useParams} from "react-router-dom";
import {images} from "../constants";

import './scss/Gallery.scss';

import {Services, Statement, About as AboutSection, Brands} from "../container";


import OwlCarousel from "react-owl-carousel";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {BiCaretRight, BiRightArrow} from "react-icons/bi";
import videoBgMp4 from "../assets/our-stock-is-rising.mp4";
import {AiOutlineLineChart} from "react-icons/ai";


import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'


function ProfessionalDevelopment() {



    const [updates, setUpdates] = useState(null);
    const [services, setServices] = useState(null);
    const {slug} = useParams();

    const [pdhome, setPdHome] = useState(null);




    const [imgGalley, setImgGallery] = useState([]);
    const [pd, setPd] = useState([]);


    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 40);
        });
        return () => {
            window.removeEventListener('scroll', () => {
                setScroll(false);
            })
        }
    }, []);



    useEffect(() => {
        const query = '*[_type == "pd"]|order(orderRank)';

        client.fetch(query)
            .then((data) => {
              setPd(data)
            });
        return () => {
          setPd([])
        }
    },[])


    useEffect(() => {
      client.fetch(`*[_type == "pdhome" && slug.current == 'professional-development']{
          name,
          _id,
          slug,
          heading,
          description,
      }`).then((data) => setPdHome(data[0]))
          .catch(console.error)
  }, [slug]);




    useEffect(() => {
        client.fetch(`*[_type == "updates" && slug.current == 'updates']{
            name,
            _id,
            slug,
            preheading,
            heading,
            description,
            mailLink,
            mailText
        }`).then((data) => setUpdates(data[0]))
            .catch(console.error)
    }, [slug]);






    useEffect(() => {
        client.fetch(`*[_type == "service"]|order(orderRank)`

        ).then((data) => {
            setServices(data)
        })

    }, []);



    if(!pd) return (
        <div className="preloader">
            <div className="status"></div>
        </div>
    )

    if(!pdhome) return (
      <div className="preloader">
          <div className="status"></div>
      </div>
  )


    if(!updates) return (
        <div className="preloader">
            <div className="status"></div>
        </div>
    )
    // if(!services) return <div>Loading.....</div>


    return (
        <>

            <section id="home-4">
                <video className="home-bg-video" src={videoBgMp4} autoPlay loop muted playsInline>
                </video>
                <div id="home-overlay-2"></div>
            </section>

            <div className="map-color-3" >
                <div className="row" style={{marginRight: '0'}}>
                    <div className="home-headings tools-p-align">
                        <div className="horizontal-heading influencer-name">
                            <h1 className="home-headings-2 influencer-name-h1"><span className='influencer-name-span'> 
                            {/* {updates.name} */}
                            {pdhome.name}
                            </span></h1>
                        </div>
                    </div>
                </div>
            </div>



            <section id="portfolio">

                <div className="isotope-filters">
                    <div className="container">

                    </div>

                    {/* Portfolio items Wrapper */}

                        <section id="about2" style={{height: 'calc(100vh - 140px)', display: 'table', width: '100%'}}>
                            <div className="about-01" style={{display: 'table-cell', verticalAlign: 'middle'}}>
                                <div className="container" style={{marginTop: '60px', marginBottom: '60px'}}>

                                    <div className="horizontal-heading main-about" style={{color: '#000', maxWidth: '600px', margin: '0 auto'}}>
                                        <h2 style={{marginBottom: '30px'}}> <strong ></strong>{pdhome.heading}</h2>
                                        <h5 style={{color: '#000', textTransform: 'initial'}}>{pdhome.description}</h5>

                                    </div>
                                    <hr style={{margin: '1.5rem', border: '2px solid #213805'}}/>

                                    <section id="portfolio-wrapper">
                                        <div className="container-fluid center-pics">

                                            <div className="row no-gutters">

                                                <div className="isotope-filters" style={{paddingLeft: '0', paddingRight: '0'}}>

                                                    {pd.map((img, index) => (


                                                        <div key={index} className="col-lg-3 col-md-3 col-sm-6 col-xs-12 div-size filter women client-custom">

                                                            <div className="portfolio-item-2 portfolio-pic no-underline">



                                                                <Link to={'/' + img.slug.current}

                                                                >
                                                                    <img src={urlFor(img.imageUrl).height(800).width(800)} className="img-responsive"
                                                                        alt="portfolio 01"/>

                                                                    <div className="portfolio-item-overlay-2 margin-top-g">
                                                                        <div className="portfolio-item-details text-center">
                                                                            {/*Item Header*/}
                                                                            <h3 style={{textTransform: 'initial'}}>{img.name}</h3>

                                                                            {/*Item Strips*/}
                                                                            {/* <span></span> */}

                                                                            {/*Item Description */}

                                                                            {/* <p className='influencer-description'>{img.description}</p> */}


                                                                        </div>
                                                                    </div>

                                                                </Link>
                                                            </div>
                                                            <div style={{marginTop: '20px'}}>
                                                            <h5 style={{textAlign: 'center'}}>{img.name}</h5>
                                                            <p style={{textAlign: 'center'}}>{img.date}</p>
                                                            </div>
                                                        
                                                        </div>

                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                    </section>

                                    
                                </div>
                            </div>
                        </section>
                </div>



            </section>




            <Statement/>

        </>
    );
}

export default ProfessionalDevelopment;